import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Img from 'gatsby-image';
import GoalieImageSlider from '../components/GoalieImageSlider';

import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  grayRow: {
    backgroundColor: 'rgba(227, 227, 227, 0.5)',
    padding: '16px 35px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  playStoreLink: {
    textDecoration: 'none',
  },
  playStore: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 90,
    fontWeight: 'bold',
    color: '#48affd',
  },
  subtext: {
    color: '#666',
    fontSize: 12,
    marginTop: 8,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 32,
  },
  divider: {
    marginTop: 16,
  },
  hero: {
    marginBottom: theme.spacing(3),
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black',
    marginBottom: 16,
  },
  freeText: {
    fontSize: 40,
    fontWeight: 'bold',
    marginLeft: 30,
    color: 'black',
  },
}));

export default function Goalie() {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      goalie: file(relativePath: { eq: "goalie/goalie-512.png" }) {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      playStore: file(relativePath: { eq: "goalie/play_store.png" }) {
        childImageSharp {
          fixed(height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Container maxWidth="lg" component="main" className={classes.heroContent}>
        <div className={classes.titleContainer}>
          <Img
            style={{ borderRadius: 10, marginTop: 26 }}
            fixed={data.goalie.childImageSharp.fixed}
          />
          <div className={classes.title}>
            <Typography className={classes.titleText}>Goalie</Typography>
            <div style={{ marginTop: -24 }}>
              A tracker for the absent minded
            </div>
          </div>
        </div>
        <Grid
          className={classes.content}
          container
          spacing={4}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid className={classes.grayRow} item xs={12}>
                <div className={classes.headerText}>All-in-One</div>
                Goalie combines features from reminder tracking, habit tracking,
                and goal tracking apps and puts them all in one place. Goalie
                will remind and track your progress on your way to develop those
                habits you've always wanted.
                <Divider className={classes.divider} />
              </Grid>
              <Grid className={classes.grayRow} item xs={12}>
                <div className={classes.headerText}>Repeatable Reminders</div>
                Goalie's built-in reminders uses the device's notifications
                center so you'll never forget a thing. Set unlimited reminders
                easily for any day of the week with the quick add or custom time
                menus. Unlimited reminders is free, so you don't have to pick
                and choose which goals to track. Tracking goals, developing good
                habits, breaking bad habits, and being reminded to do something
                has never been so fast!
                <Divider className={classes.divider} />
              </Grid>
              <Grid className={classes.grayRow} item xs={12}>
                <div className={classes.headerText}>Track your progress</div>
                With a simple left or right swipe, you can track your goal's
                progress for the day. Goalie shows a 7 day trend for all your
                goals in one place making it easy to review how you're doing. A
                goal tracker is a great way to reverse bad habits. In the Goal
                details, you can see a more detailed graph, and even your best
                or current streak.
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <GoalieImageSlider
                width={300}
                height={530}
                style={{ margin: '50px 26px 80px 26px' }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <a
              className={classes.playStoreLink}
              href="https://play.google.com/store/apps/details?id=willoughby.com.goalie"
            >
              <div className={classes.playStore} style={{ maxHeight: '100%' }}>
                <Img fixed={data.playStore.childImageSharp.fixed} />
                <span className={classes.freeText}>Free</span>
              </div>
            </a>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Customizable</Typography>
                    <div className={classes.subtext}>
                      Easily change the colors of each of your goals. With
                      Goalie's color menu choosing a color has never been
                      easier. Different colors provide an easy way to organize
                      goals that are similar, or provide a more personal feel.
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Weekly Trends</Typography>
                    <div className={classes.subtext}>
                      It should be easy to see your progress on your goals
                      without having to painfully tap on each one to see
                      progress. Goalie includes a unique goal tracker
                      functionality that shows the past 7 days of each goal in a
                      single list. You'll be motivated to keep working towards
                      your goals, develop good habits, or break bad habits
                      because the trend is so easily accessible.
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">What others are saying</Typography>
                    <div className={classes.subtext}>
                      "Goalie helped me climb mountains! I've never been so
                      productive in my life."
                      <br />
                      - Jack
                      <br />
                      <br />
                      "After Jack got Goalie, he has never missed fetching a
                      pail of water. I love how I can see all my goals on one
                      screen. It motivates me to keep going."
                      <br />- Jill
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}
