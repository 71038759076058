import React, { useCallback, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled';

const useStyles = makeStyles(theme => ({
  image: {
    marginLeft: 26,
  },
  slider: {
    width: 330,
    height: 550,
    overflow: 'hidden',
    display: 'flex',
  },
  playButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  playButton: {
    marginLeft: -30,
  },
}));

export default function GoalieImageSlider() {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      slides: allFile(
        sort: { fields: name }
        filter: {
          relativeDirectory: { eq: "goalie" }
          childImageSharp: { fluid: { originalName: { regex: "/screen/" } } }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(width: 300) {
                src
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      phone: file(relativePath: { eq: "goalie/Nexus-5.png" }) {
        childImageSharp {
          fixed(width: 350) {
            src
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  let [currentIndex, setCurrentIndex] = useState(0);
  let [nextStyle, setNextStyle] = useState({});
  let [sliding, setSliding] = useState(false);
  let [paused, setPaused] = useState(false);
  let nextIndex = currentIndex + 1;
  if (nextIndex >= data.slides.edges.length) {
    nextIndex = 0;
  }
  let onPause = () => {
    setPaused(true);
  };
  let onPlay = () => {
    setPaused(false);
  };
  let onNavRight = useCallback(() => {
    if (sliding) return;
    setSliding(true);
    setNextStyle({ transform: 'translate(-326px, 0)', transition: '0.5s' });
  }, [sliding]);
  let onSlideEnd = () => {
    if (!sliding) return;
    setCurrentIndex(nextIndex);
    setNextStyle({});
    setSliding(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (paused) return;
      onNavRight();
    }, 3000);
    return () => clearInterval(interval);
  }, [paused, onNavRight]);

  return (
    <div style={{ marginTop: 32 }}>
      <Img
        draggable={false}
        style={{ position: 'absolute', marginTop: -56 }}
        fixed={data.phone.childImageSharp.fixed}
      />
      <div className={classes.slider}>
        <div>
          <Img
            draggable={false}
            className={classes.image}
            fixed={data.slides.edges[currentIndex].node.childImageSharp.fixed}
          />
        </div>
        <div style={nextStyle} onTransitionEnd={onSlideEnd}>
          <Img
            key="next"
            loading="eager"
            draggable={false}
            className={classes.image}
            fixed={data.slides.edges[nextIndex].node.childImageSharp.fixed}
          />
        </div>
      </div>
      <div className={classes.playButtonContainer}>
        {paused ? (
          <IconButton
            className={classes.playButton}
            onClick={onPlay}
            color="primary"
          >
            <PlayCircleFilled />
          </IconButton>
        ) : (
          <IconButton
            className={classes.playButton}
            onClick={onPause}
            color="primary"
          >
            <PauseCircleFilled />
          </IconButton>
        )}
      </div>
    </div>
  );
}
